<template>

<com-list api="treatments" :block="block" v-on:loaded="onListLoaded">

	<ul class="treatments">
		
		<li class="treatments-item" v-for="(item, index) in list" :key="index">

			&bull; <router-link :to="$link($route.path + '/' + item.url)">{{ item.name }}</router-link>

		</li>

	</ul>

</com-list>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-list': () => import('./common/List')
	}

}

</script>

<style scoped>

.treatments-item {
	font-size: 16px;
	line-height: 20px;
	padding: 4px 0px;
	color: #1277d4;
}

.treatments-item:hover {
	text-decoration: underline;
}

</style>
